import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Container } from 'react-bootstrap';

import { setModelsData, setErrorData, resetBuilderData } from 'store/entities/data';
import { setGrade } from 'store/entities/config';
import { resetAppState } from 'store/entities/app';

import LoadingSpinner from 'components/LoadingSpinner';
import NotFound from 'components/NotFound';
import LocalizedLink from 'components/LocalizedLink';

import { useModelsDataRequest } from 'api/requests';

const ModelSelection = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    const { modelsData, errorData } = useSelector(state => state.data);

    const ENV = window.ENV[window.location.hostname] || window.ENV.default;

    const dataModels = useModelsDataRequest({
        params: {
            params: {
                brand: ENV.BRAND_NAME,
                isActive: true
            }
        },
        success: (result) => {
            dispatch(setModelsData(result.data));
        },
        error: (error) => {
            dispatch(setErrorData({
                type: 'specifications',
                message: error.message,
                refetch: true
            }));
        }
    });

    React.useEffect(() => {
        dispatch(resetAppState());
        dispatch(resetBuilderData());
        dispatch(setGrade({}));
    }, []);

    const renderModel = (data) => data.map(model => (
        <LocalizedLink className="c-model" to={`/grade?id=${model.id}`} key={model.id}>
            <figure className="c-model__img">
                <img src={model.pictures.thumb} alt={model.name} />
            </figure>
            <div className="c-model__body">
                <h3 className="c-model__title">{model.name}</h3>
            </div>
            
        </LocalizedLink>
    ));

    const noData = !dataModels.isFetching && !Object.keys(modelsData).length;

    return (<>
        {dataModels.isFetching && (
            <LoadingSpinner />
        )}
        {!errorData?.type ? (
            <section className="c-section">
                <Container>
                    <h5 className="c-section__subheader">{t('model_selection_descr')}</h5>
                    <div className="c-models-list">
                        {renderModel(modelsData)}
                    </div>
                    {noData && (
                        <h5 className="c-section__subheader">No cars found</h5>
                    )}
                </Container>
            </section>
        ) : (
            <NotFound error={errorData} onRefetch={dataModels.refetch} />
        )}
    </>);
};

export default ModelSelection;
