import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';

import { Container } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper';

import { setSpecificationsData, setBodyData, setErrorData } from 'store/entities/data';
import { setGrade } from 'store/entities/config';
import { resetAppState } from 'store/entities/app';

import LoadingSpinner from 'components/LoadingSpinner';
import NotFound from 'components/NotFound';
import { GradeCard } from 'components/Cards';

import { ReactComponent as ArrowLeft } from 'assets/images/icons/ArrowLeft.svg';
import { ReactComponent as ArrowRight } from 'assets/images/icons/ArrowRight.svg';

import { useSpecificationsDataRequest } from 'api/requests';

const GradeSelection = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const { specification } = useSelector(state => state.config);
    const { specificationsData, errorData } = useSelector(state => state.data);
    const { id: model, code } = queryString.parse(location.search);

    const ENV = window.ENV[window.location.hostname] || window.ENV.default;

    const gradesData = Object.keys(specificationsData)
        .map(key => [...specificationsData[key]]
            .sort((a, b) => a.price - b.price)[0] || null
        )
        .sort((a, b) => a.price - b.price);

    const selectGrade = (gradeId) => {
        const gradeData = gradesData.find(item => item.grade.id === gradeId) || {};
        const gradeConfig = {
            model: gradeData.model?.id,
            specification: gradeData.eic,
            gradeId: gradeData.grade?.id,
            body: gradeData.body?.id,
            engine: gradeData.engine?.id
        };
        dispatch(resetAppState());
        dispatch(setGrade(gradeConfig));
        dispatch(setBodyData(specificationsData[gradeConfig.gradeId]));
    };

    const dataSpecs = useSpecificationsDataRequest({
        params: {
            params: {
                'brand.name': ENV.BRAND_NAME,
                'model.id': model,
                'model.code': code,
                isActive: true
            }
        },
        config: {
            enabled: false,
        },
        success: (result) => {
            dispatch(setSpecificationsData(result.data));
        },
        error: (error) => {
            dispatch(setErrorData({
                type: 'specifications',
                message: error.message,
                refetch: true
            }));
        }
    });

    React.useEffect(() => {
        (model || code) ? dataSpecs.refetch() : dispatch(setErrorData({
            type: 'model',
            message: 'Model ID or model CODE is missing',
            redirect: t('fallback_url')
        }));
    }, [model, code]);

    React.useEffect(() => {
        const firstGradeId = gradesData[0]?.grade?.id;
        if (!specification && firstGradeId) selectGrade(firstGradeId);
    }, [specificationsData]);

    const handleGradeSelect = (gradeId) => {
        if (gradeId) {
            selectGrade(gradeId);
            navigate(`/${i18n.language}/select`);
        } else {
            console.log('Missing grade ID');
        }
    };

    const swiperProps = {
        className: 'card-swiper',
        cssMode: true,
        slidesPerView: 'auto',
        navigation: {
            prevEl: '.swiper-button-prev',
            nextEl: '.swiper-button-next',
        },
        pagination: {
            clickable: true,
        },
        mousewheel: true,
        keyboard: true,
        modules: [Navigation, Pagination, Mousewheel, Keyboard]
    };

    const noData = !dataSpecs.isFetching && !Object.keys(specificationsData).length;

    return (<>
        {dataSpecs.isFetching && (
            <LoadingSpinner />
        )}
        {!errorData?.type ? (
            <section className="c-section c-grades">
                <Container>
                    <h5 className="c-section__subheader">{t('grade_selection_descr')}</h5>
                    <Swiper {...swiperProps}>
                        {gradesData.map(grade => (
                            <SwiperSlide key={grade.eic}>
                                <GradeCard gradeData={grade} onSelect={handleGradeSelect} />
                            </SwiperSlide>
                        ))}
                        <button className="swiper-button swiper-button-prev" type="button">
                            <ArrowLeft />
                        </button>
                        <button className="swiper-button swiper-button-next" type="button">
                            <ArrowRight />
                        </button>
                    </Swiper>
                    {noData && (
                        <h5 className="c-section__subheader">No cars found</h5>
                    )}
                </Container>
            </section>
        ) : (
            <NotFound error={errorData} onRefetch={dataSpecs.refetch} />
        )}
    </>);
};

export default GradeSelection;
