import { useTranslation } from 'react-i18next';
import { renderToString } from 'react-dom/server';

import { Button } from 'react-bootstrap';

import Price from 'components/Price';

export const GradeCard = ({ gradeData, onSelect }) => {
    const { t } = useTranslation();
    const { grade, model, body, engine, price } = gradeData;

    const gradeId = grade?.id;
    const gradeName = grade?.name || '';
    const gradeImg = model?.pictures?.main;
    const bodyName = body?.name || '';
    const engineType = engine?.type || '';

    const badge = ['hybrid', 'electric'].filter(item => engineType.toLowerCase().includes(item))[0];

    return (
        <div className="c-grade">
            <figure className="c-grade__img">
                <img src={gradeImg} alt={gradeName} />
            </figure>
            <div className="c-grade__body">
                <h3 className="c-grade__title">{gradeName}</h3>
                <span className="c-badge-list">
                    <span className={`c-badge c-badge--${badge || 'default'}`}>{engineType}</span>
                </span>
                <p className="c-grade__price">
                    <strong className="c-price c-price--new">
                        <span className="c-price__title">{t('starting_price', {
                            price: renderToString(<Price price={price} />)
                        })}</span>
                    </strong>
                </p>
            </div>
            <div className="c-grade__footer">
                <p className="c-grade__tech">{bodyName}</p>
                <p className="c-grade__btnline">
                    <Button variant="primary" onClick={() => onSelect(gradeId)}>
                        {t('grade_select_button')}
                    </Button>
                </p>
            </div>
        </div>
    );
};
