import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { Button, Container, Image } from 'react-bootstrap';

import Accessories from 'components/Accessories';
import Packages from 'components/Packages';
import AssemblySliderSelectors from 'components/AssemblySliderSelectors';
import LocalizedLink from 'components/LocalizedLink';
import RewindButton from 'components/RewindButton';
import BottomBar from 'components/BottomBar';
import { PackagesModal } from 'components/modals';
import LoadingSpinner from 'components/LoadingSpinner';
import Price from 'components/Price';
import NotFound from 'components/NotFound';
import ThreeSixty from 'components/360Viewer/index';

import { getActiveInteriorPicture } from 'utils/dataUtils';
import { setColor, setInterior, setWheels, updateAccessories, updateExtraPackages } from 'store/entities/config';
import { setCompatibilityData, setErrorData } from 'store/entities/data';

import useLocale from 'hooks/useLocale';
import useBreakPoints from 'hooks/useBreakPoints';

import { ReactComponent as FullscreenIco } from 'assets/images/icons/Fullscreen.svg';
import { ReactComponent as CloseFullscreenIco } from 'assets/images/icons/ExitFullscreen.svg';
import { ReactComponent as ArrowRight } from 'assets/images/icons/ArrowRight.svg';
import PlaceholderCar from 'assets/images/placeholder-car.png';

import { useSpecificationDataRequest, useCompatibilityDataRequest } from 'api/requests';

const Assemble = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const locale = useLocale();

    const [option, setOption] = React.useState(null);
    const [resolve, setResolve] = React.useState(null);
    const [pictures, setPictures] = React.useState([]);

    const { model, specification, colorCode, interior, wheels, extraPackages, accessories } = useSelector(state => state.config);
    const { builderData, errorData } = useSelector(state => state.data);
    const { selectedTab } = useSelector(state => state.app);

    const ENV = window.ENV[window.location.hostname] || window.ENV.default;

    const handleFullScreen = useFullScreenHandle();
    const { isSmallerThanLg } = useBreakPoints();

    const packagesData = builderData?.options || [];
    const selectedPackages = packagesData.filter(item => extraPackages.includes(item.id)).map(item => item.code);

    const accessoriesData = builderData?.accessories || {};
    const allAccessories = Object.keys(accessoriesData).reduce((result, key) => result.concat(accessoriesData[key]), []);
    const selectedAccessories = allAccessories.filter(item => accessories.includes(item.id)).map(item => item.code);

    const imageMain = builderData.exteriors?.find(item => item.code === colorCode)?.pictures.main;
    const isInteriorTab = selectedTab === 'interiors';

    const resolveConfirm = () => {
        let resolvedPackages = [...extraPackages];
        let resolvedAccessories = [...accessories];
        resolve.incompatibleOptions?.forEach(set => // remove conflict options
            set.forEach(item => item.hasOwnProperty('fittingTime') ?
                resolvedAccessories = resolvedAccessories.filter(id => id !== item.id) :
                resolvedPackages = resolvedPackages.filter(id => id !== item.id)
            )
        );
        resolve.requiredOptions?.forEach(set => // add additional options
            set.forEach(item => item.hasOwnProperty('fittingTime') ?
                resolvedAccessories.push(item.id) :
                resolvedPackages.push(item.id)
            )
        );
        switch (option.type) {
            case 'options':
                option.check ?
                    resolvedPackages = resolvedPackages.filter(id => id !== resolve.option.id) :
                    resolvedPackages.push(resolve.option.id);
                break;
            case 'accessories':
                option.check ?
                    resolvedAccessories = resolvedAccessories.filter(id => id !== resolve.option.id) :
                    resolvedAccessories.push(resolve.option.id);
                break;
            case 'exteriors':
                dispatch(setColor(resolve.option.code));
                break;
            case 'wheels':
                dispatch(setWheels(resolve.option.code));
                break;
            case 'interiors':
                dispatch(setInterior(resolve.option.code));
                break;
        }
        dispatch(updateAccessories(resolvedAccessories));
        dispatch(updateExtraPackages(resolvedPackages));
        dispatch(setCompatibilityData(resolve));
        setOption(null);
        setResolve(null);
    };

    const resolveCancel = () => {
        setOption(null);
        setResolve(null);
    };

    const dataSpecification = useSpecificationDataRequest({
        params: {
            specification,
            params: {
                brand: ENV.BRAND_NAME,
                [option?.check ? 'remove' : 'add']: option?.code,
                'options.code': [
                    colorCode,
                    //interior,
                    wheels
                ].filter(item => item),
                language: locale
            }
        },
        config: {
            enabled: false,
        },
        success: (result) => {
            const pics = result.data.exterior.pictures['360'] || [];
            setPictures(pics);
        },
        error: (error) => {
            console.log('Specification error: ', error.message);
        }
    });

    const dataCompatibility = useCompatibilityDataRequest({
        params: {
            specification,
            params: {
                brand: ENV.BRAND_NAME,
                [option?.check ? 'remove' : 'add']: option?.code,
                'options.code': [
                    ...selectedPackages,
                    ...selectedAccessories,
                    colorCode,
                    interior,
                    wheels
                ].filter(item => item),
                language: locale
            }
        },
        config: {
            enabled: false,
        },
        success: (result) => {
            if (result.data?.requiredOptions || result.data?.incompatibleOptions) result.data.resolve = true; // need confirmation for resolving
            setResolve(result.data);
        },
        error: (error) => {
            console.log('Compatibility error: ', error.message);
        }
    });

    React.useEffect(() => {
        if (resolve && !resolve.resolve) resolveConfirm();
    }, [resolve]);

    React.useEffect(() => {
        if (option) dataCompatibility.refetch();
    }, [option]);

    React.useEffect(() => {
        if (!!colorCode) dataSpecification.refetch();
    }, [colorCode, wheels]);

    React.useEffect(() => {
        dispatch(setErrorData(!specification ? {
            type: 'model',
            message: 'Model ID or model CODE is missing',
            redirect: '/'
        } : {}));
    }, [specification]);

    const packagesModalProps = {
        show: resolve?.resolve,
        data: resolve,
        target: option,
        onConfirm: resolveConfirm,
        onCancel: resolveCancel
    };

    const threeSixtyProps = {
        buttonClass: 'button',
        amount: 24,
        autoplay: true,
        spinReverse: true,
        key: pictures,
        images: pictures
    };

    const isThreeSixtyShow = pictures.length && !dataSpecification.isFetching;

    return (<>
        {dataCompatibility.isFetching && (
            <LoadingSpinner />
        )}
        {!errorData?.type ? (<>
            {builderData.eic ? (<>
                <section className={`c-section c-threesixty${selectedTab === 'interiors' ? ' c-threesixty--interior' : ''}`}>
                    <FullScreen handle={handleFullScreen} className="c-threesixty__vehicle c-vehicle">
                        <Container className="c-vehicle__body">
                            <div className="c-vehicle__caption c-caption">
                                <h2 className="c-caption__title">{builderData.model?.name}</h2>
                                <p className="c-caption__descr">{builderData.name}</p>
                                <p className="c-caption__price c-price">
                                    <span className="c-price__title">{t('price')}</span>
                                    <strong className="c-price__value"><Price price={builderData.price} /></strong>
                                </p>
                                <p className="c-caption__btn">
                                    <RewindButton href={`/grade?id=${model}`} text={t('switch_vehicle')} />
                                </p>
                            </div>
                            {!isSmallerThanLg && (
                                <p className="c-vehicle__action">
                                    {handleFullScreen.active ? (
                                        <Button onClick={handleFullScreen.exit} variant="link">
                                            {t('close_button')}
                                            <CloseFullscreenIco className="btn-ico btn-ico-right" />
                                        </Button>
                                    ) : (
                                        <Button onClick={handleFullScreen.enter} variant="link">
                                            {t('full_view')}
                                            <FullscreenIco className="btn-ico btn-ico-right" />
                                        </Button>
                                    )}
                                </p>
                            )}
                        </Container>
                        {isInteriorTab || !isThreeSixtyShow ? (
                            <figure className="c-vehicle__img">
                                <Image src={
                                    isInteriorTab ?
                                    getActiveInteriorPicture(builderData.interiors, interior) :
                                    imageMain || PlaceholderCar
                                } />
                            </figure>
                        ) : (
                            <ThreeSixty {...threeSixtyProps} />
                        )}
                    </FullScreen>
                    <div className="c-threesixty__nav">
                        <Container>
                            <AssemblySliderSelectors onChange={setOption} />
                        </Container>
                    </div>
                </section>
                <section className="c-section">
                    <Container>
                        <Packages onChange={setOption} />
                        <Accessories onChange={setOption} />
                    </Container>
                </section>
                <BottomBar action={
                    <LocalizedLink to="/conclusion" className="btn btn-link">
                        {t('summary_button')}
                        <ArrowRight className="btn-ico btn-ico-right" />
                    </LocalizedLink>
                }/>
                <PackagesModal {...packagesModalProps} />
            </>) : (
                <NotFound error={{
                    type: 'builder-data',
                    route: '/select',
                    message: 'Please, select vehicle engine'
                }} />
            )}
        </>) : (
            <NotFound error={errorData} />
        )}
    </>);
};

export default Assemble;
