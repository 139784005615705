import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { renderToString } from 'react-dom/server';

import { Form, Button } from 'react-bootstrap';

import Price from 'components/Price';
import { ReactComponent as Info } from 'assets/images/icons/Info.svg';

export const CarPartCard = ({ engineData, bodyData, onSelect, onDetails, buttonText }) => {
    const { t } = useTranslation();
    const { body, specification } = useSelector(state => state.config);

    const data = engineData || bodyData || {};
    const eic = data.eic;
    const id = data.id;
    const title = data.name;
    const price = data.price;
    const info = data.style;
    const doors = data.doors;
    const seats = data.seats;

    const badge = ['hybrid', 'electric'].filter(item => data.type?.toLowerCase().includes(item))[0];
    const isActive = React.useMemo(() => eic ? eic === specification : id === body, [body, specification, data]);

    const handleSelect = () => {
        const firstEngine = data.engines?.[0];
        const engineEic = firstEngine?.eic || null;
        const engineId = engineEic && engineEic !== specification ? firstEngine.id : null;
        eic ? onSelect(id, eic) : onSelect(id, engineId, engineEic);
    };

    const buttonProps = {
        id: `carpart-${id}`,
        className: 'c-engine__check',
        type: 'radio',
        onChange: handleSelect,
        checked: isActive,
        label: t(buttonText)
    };

    return (
        <Form.Label className={`c-engine ${isActive ? 'active' : ''}`}>
            <div className="c-engine__head">
                <h3 className="c-engine__title">{title}</h3>
            </div>
            <div className="c-engine__body">
                {price && (
                    <p className="c-engine__price">
                        <strong className="c-price c-price--new">
                            <span className="c-price__title">
                                {t('starting_price', { price: renderToString(<Price price={price} />) })}
                            </span>
                            {/*<small className="c-price__hint">
                              <Info className="c-price__hint-ico" title="Some tooltip text" data-toggle="tooltip" />
                            </small>*/}
                        </strong>
                    </p>
                )}
                {info && (
                    <p className="c-engine__descr">{info}</p>
                )}
                <ul className="c-engine__tech">
                    <li className="c-engine__tech-item">
                        {data.type && (
                            <div className="c-tech">
                                <svg className="c-tech__ico" width="16" height="16" viewBox="0 0 30 30"><path d="M14.215 5.261H7.101v5.424h7.113V5.261h.001zM5.432 3.635h10.451v8.679H5.432V3.635zm10.451-1.873h-10.5c-1.275 0-1.767.542-1.767 1.825V28.24H17.65V3.586c0-1.282-.491-1.825-1.767-1.825v.001zm3.484 8.923c2.355 0 3.533 1.184 3.533 3.503v7.347c0 2.022 1.03 3.252 2.747 3.252s2.552-.983 2.552-2.956c0-3.207-3.533-5.918-3.533-11.194v-1.53c0-4.633-.883-6.507-3.533-7.345V.083c3.73.739 6.232 3.701 6.968 8.728h-1.717v1.331c0 5.326 3.533 8.333 3.533 11.689 0 2.958-1.521 4.633-4.269 4.633-2.65 0-4.464-1.971-4.464-4.929V14.09c0-1.282-.491-1.778-1.816-1.778V28.24h1.767v1.677H.083V28.24h1.816V3.586c0-2.368 1.128-3.503 3.484-3.503h10.5c2.355 0 3.484 1.135 3.484 3.503v7.099z"></path></svg>
                                <span className={`c-tech__title c-badge mx-1 c-badge--${badge || 'default'}`}>{data.type}</span>
                            </div>
                        )}
                        {typeof doors !== 'undefined' && (
                            <div className="c-tech">
                                <b className="c-tech__ico">{doors}</b>
                                <span className="c-tech__title c-badge mx-1">Doors</span>
                            </div>
                        )}
                    </li>
                    <li className="c-engine__tech-item">
                        {data.transmission && (
                            <div className="c-tech">
                                <svg className="c-tech__ico" width="16" height="16" viewBox="0 0 30 30"><path d="M16.624 4.119c-3.127 0-4.894 1.642-4.894 4.753 0 3.11 1.767 4.752 4.894 4.752 3.127 0 4.895-1.641 4.895-4.752 0-3.111-1.768-4.753-4.895-4.753Zm-1.36 10.888-1.586 6.222h-1.676l1.722-6.654c-2.266-.864-3.58-2.852-3.58-5.703 0-3.889 2.447-6.222 6.48-6.222 4.034 0 6.481 2.333 6.481 6.222 0 3.24-1.722 5.444-4.668 6.049l-1.314 6.308h-1.677l1.36-6.136h-.182c-.453 0-.634 0-1.36-.086Zm-6.752 9.29h12.962v-1.47H8.512v1.47Zm-3.218 3.067h19.397v-1.47H5.294v1.47Z"></path></svg>
                                <span className="c-tech__title c-badge mx-1">{data.transmission}</span>
                            </div>
                        )}
                        {typeof seats !== 'undefined' && (
                            <div className="c-tech">
                                <b className="c-tech__ico">{seats}</b>
                                <span className="c-tech__title c-badge mx-1">Seats</span>
                            </div>
                        )}
                    </li>
                </ul>
                <p className="c-engine__btnline">
                    <Button as="a" variant="link" onClick={onDetails}>
                        <Info className="btn-ico btn-ico-left" />
                        {t('additional_info')}
                    </Button>
                </p>
            </div>
            <div className="c-engine__footer">
                <Form.Check {...buttonProps} />
            </div>
        </Form.Label>
    );
};
