import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Modal } from 'react-bootstrap';

import LoadingSpinner from 'components/LoadingSpinner';
import FinishScreen from 'components/modals/SaveCarConfigModal/FinishScreen';
import ErrorScreen from 'components/modals/SaveCarConfigModal/ErrorScreen';

import { setModalState } from 'store/entities/app';
import { useConfigRequest } from 'api/requests';

export const SaveCarConfigModal = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [savedConfigId, setSavedConfigId] = React.useState(null);
    const [hasError, setHasError] = React.useState(false);

    const { saveConfigModal: isOpen } = useSelector(state => state.app);
    const { config } = useSelector(state => state);

    const saveConfig = useConfigRequest({
        success: (result, data) => {
            const configId = result?.data?.configId;
            setSavedConfigId(configId);
        },
        error: (error) => {
            setHasError(true);
        },
    });

    React.useEffect(() => {
        if (isOpen) saveConfig.mutate(config);
    }, [isOpen]);

    const resetState = () => {
        setSavedConfigId(null);
        setHasError(false);
    };

    const handleClose = () => {
        resetState();
        dispatch(setModalState({
            modal: 'saveConfigModal',
            newState: false,
        }));
    };

    return (
        <Modal show={isOpen} onHide={handleClose} size="lg" animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>{t('modal_title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="py-5 position-relative">
                    {saveConfig.isFetching && (
                        <LoadingSpinner />
                    )}
                    {savedConfigId && (
                        <FinishScreen code={savedConfigId} />
                    )}
                    {hasError && (
                        <ErrorScreen onReset={resetState} />
                    )}
                </div>
            </Modal.Body>
        </Modal>
    );
};
