import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Container, Image, Button } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper';

import useLocale from 'hooks/useLocale';

import { setBuilderData, setRawBuilderData, setErrorData } from 'store/entities/data';
import { setConfig, setBody, setEngine, setSpecification } from 'store/entities/config';

import { CarPartCard } from 'components/Cards';
import { TechDataModal } from 'components/modals';

import LocalizedLink from 'components/LocalizedLink';
import RewindButton from 'components/RewindButton';
import BottomBar from 'components/BottomBar';
import Price from 'components/Price';

import LoadingSpinner from 'components/LoadingSpinner';
import NotFound from 'components/NotFound';

import PlaceholderCar from 'assets/images/placeholder-car.png';
import { ReactComponent as ArrowLeft } from 'assets/images/icons/ArrowLeft.svg';
import { ReactComponent as ArrowRight } from 'assets/images/icons/ArrowRight.svg';

import { useBuilderDataRequest } from 'api/requests';

const CarPartSelection = () => {
    const { t } = useTranslation();
    const locale = useLocale();
    const dispatch = useDispatch();

    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [modalType, setModalType] = React.useState('equipment');

    const { model, specification, body } = useSelector(state => state.config);
    const { builderData, bodyData, errorData } = useSelector(state => state.data);

    const ENV = window.ENV[window.location.hostname] || window.ENV.default;

    const image = builderData.pictures?.main || builderData.exteriors?.[0]?.pictures?.main || PlaceholderCar;

    const engines = bodyData[body]?.engines || [];
    const bodies = Object.keys(bodyData || {});

    const handleSelectEngine = (engineId, eic) => {
        dispatch(setEngine(engineId));
        dispatch(setSpecification(eic));
    };

    const handleSelectBody = (bodyId, engineId, eic) => {
        dispatch(setBody(bodyId));
        if (engineId || eic) handleSelectEngine(engineId, eic);
    };

    const handleModal = (type) => {
        setModalType(type);
        setIsModalOpen(true);
    };

    const dataBuilder = useBuilderDataRequest({
        params: {
            specification,
            params: {
                brand: ENV.BRAND_NAME,
                language: locale
            }
        },
        config: {
            enabled: false,
        },
        success: (result) => {
            dispatch(setBuilderData(result.data));
            dispatch(setRawBuilderData(result.data));
            dispatch(setConfig(result.data.default));
            dispatch(setErrorData({}));
        },
        error: (error) => {
            dispatch(setErrorData({
                type: 'builder-data',
                message: error.message,
                refetch: true
            }));
        }
    });

    React.useEffect(() => {
        specification ? dataBuilder.refetch() : dispatch(setErrorData({
            type: 'model',
            message: 'Model ID or model CODE is missing',
            redirect: '/'
        }));
    }, [specification]);

    const swiperProps = {
        className: 'card-swiper carpart-swiper',
        cssMode: true,
        slidesPerView: 'auto',
        navigation: {
            prevEl: '.swiper-button-prev',
            nextEl: '.swiper-button-next',
        },
        pagination: {
            clickable: true,
        },
        mousewheel: true,
        keyboard: true,
        modules: [Navigation, Pagination, Mousewheel, Keyboard]
    };

    return (<>
        {dataBuilder.isFetching && (
            <LoadingSpinner />
        )}
        {!errorData?.type ? (<>
            {builderData.eic && (
                <section className="c-section">
                    <div className="c-vehicle">
                        <Container className="c-vehicle__body">
                            <div className="c-vehicle__caption c-caption">
                                <h2 className="c-caption__title">{builderData.name}</h2>
                                <p className="c-caption__price c-price">
                                    <span className="c-price__title">{t('price')}</span>
                                    <strong className="c-price__value"><Price price={builderData.price} /></strong>
                                </p>
                                <p className="c-caption__btn">
                                    <RewindButton href={`/grade?id=${model}`} text={t('switch_vehicle')} />
                                </p>
                            </div>
                            <div className="c-vehicle__info c-info">
                                <h5 className="c-info__title">{t('equipment')}</h5>
                                <ul className="c-info__list">
                                    {builderData.equipment?.slice(0, 4).map(item => (
                                        <li className="c-info__item" key={item.id}>{item.name}</li>
                                    ))}
                                </ul>
                                <p className="c-info__btn">
                                    <Button variant="light" onClick={() => handleModal('equipment')}>
                                        {t('view_more_btn')}
                                    </Button>
                                </p>
                            </div>
                        </Container>
                        <figure className="c-vehicle__img">
                            <Image src={image} />
                        </figure>
                    </div>
                </section>
            )}
            <section className="c-section c-engines">
                <Container>
                    {bodies.length > 1 && (<>
                        <h4 className="c-section__subtitle">{t('choose_body')}</h4>
                        <Swiper {...swiperProps}>
                            {bodies.map(key => (
                                <SwiperSlide key={bodyData[key].id}>
                                    <CarPartCard
                                        bodyData={bodyData[key]}
                                        onSelect={handleSelectBody}
                                        onDetails={() => handleModal('techdata')}
                                        buttonText="choose_body_button"
                                    />
                                </SwiperSlide>
                            ))}
                            <button className="swiper-button swiper-button-prev" type="button"><ArrowLeft /></button>
                            <button className="swiper-button swiper-button-next" type="button"><ArrowRight /></button>
                        </Swiper>
                    </>)}
                    <h4 className="c-section__subtitle">{t('choose_engine')} </h4>
                    {engines.length ? (
                        <Swiper {...swiperProps}>
                            {engines.map((engine, index) => (
                                <SwiperSlide key={`${engine.id}-${index}`}>
                                    <CarPartCard
                                        engineData={engine}
                                        onSelect={handleSelectEngine}
                                        onDetails={() => handleModal('techdata')}
                                        buttonText="choose_engine_button"
                                    />
                                </SwiperSlide>
                            ))}
                            <button className="swiper-button swiper-button-prev" type="button"><ArrowLeft /></button>
                            <button className="swiper-button swiper-button-next" type="button"><ArrowRight /></button>
                        </Swiper>
                    ) : (
                        <h5 className="c-section__subheader">No engines found</h5>
                    )}
                </Container>
            </section>
            <BottomBar action={
                <LocalizedLink to="/assemble" className="btn btn-link">
                    {t('continue_button')}
                    <ArrowRight className="btn-ico btn-ico-right" />
                </LocalizedLink>
            }/>
            <TechDataModal show={isModalOpen} onClose={() => setIsModalOpen(false)} type={modalType} />
        </>) : (
            <NotFound error={errorData} onRefetch={dataBuilder.refetch} />
        )}
    </>);
};

export default CarPartSelection;
