import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Accordion, ListGroup, Badge } from 'react-bootstrap';

const TechData = () => {
    const { t } = useTranslation();

    const { builderData } = useSelector(state => state.data);
    const { techdata } = builderData;

    const techdataCount = [].concat(...Object.values(techdata || {})).length;

    return !techdata ? null : (
        <Accordion.Item eventKey="accordionTech" className="c-accordion__item">
            <Accordion.Button className="c-accordion__toggler">
                <span className="c-accordion__title">{t('technical_spec')}</span>
                <Badge pill bg="dark" className="c-accordion__count">{techdataCount}</Badge>
            </Accordion.Button>
            <Accordion.Body className="c-accordion__body">
                {Object.keys(techdata).map(category => (
                    <ListGroup variant="flush" key={`techCategory-${category}`}>
                        <ListGroup.Item as="strong">
                            {category}
                        </ListGroup.Item>
                        {techdata[category].map(item => (
                            <ListGroup.Item key={`techItem-${item.code}`}>
                                <span className="list-group-label">{item.name}</span>
                                <b className="list-group-value">{item.value}</b>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                ))}
            </Accordion.Body>
        </Accordion.Item>
    );
};

export default TechData;
