import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';

import { Alert, Modal } from 'react-bootstrap';

import { setModalState } from 'store/entities/app';

import OfferForm from './OfferForm';
import LoadingSpinner from 'components/LoadingSpinner';

import { useOfferRequest } from 'api/requests';

const formDefaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    //phoneCode: '371',
    phoneNr: '',
    location: '1',
    consents: [],
    modelFamilyName: ''
};

export const OfferModal = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [step, setStep] = React.useState('form');
    const [error, setError] = React.useState('');

    const { offerModal: isOpen, offerModalType } = useSelector(state => state.app);
    const methods = useForm({ defaultValues: formDefaultValues });

    const dataOffer = useOfferRequest({
        success: (result, data) => {
            setStep('finish');
        },
        error: (error) => {
            setStep('form');
            setError(error.message);
        },
    });

    const handleClose = () => {
        const payload = {
            modal: 'offerModal',
            newState: false,
        };
        dispatch(setModalState(payload));
        setStep('form');
        setError('');
    };

    const handleSubmit = (payload) => {
        setStep('loading');
        dataOffer.mutate(payload);
    };

    const modalProps = {
        size: 'lg',
        className: 'offer-modal',
        show: isOpen,
        onHide: handleClose
    };

    return (
        <FormProvider {...methods}>
            <Modal {...modalProps}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {t(`offer_modal_${offerModalType}`)}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {step === 'form' && (<>
                        {!!error && (
                            <Alert variant="error">{error}</Alert>
                        )}
                        <OfferForm onSubmit={handleSubmit} handleStepChange={setStep} />
                    </>)}
                    {step === 'loading' && (
                        <LoadingSpinner />
                    )}
                    {step === 'finish' && (
                        <Alert variant="success">
                            {t(`form_${offerModalType}_successful`)}
                        </Alert>
                    )}
                </Modal.Body>
            </Modal>
        </FormProvider>
    );
};