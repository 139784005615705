import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import { Button, Form } from 'react-bootstrap';

import useSalesFrontConfig from 'hooks/useSalesFrontConfig';
import useSelectedAccessory from 'hooks/useSelectedAccessory';

const OfferForm = ({ onSubmit }) => {
    const { t } = useTranslation();

    const { gradeId } = useSelector(state => state.config);
    const { offerModalType } = useSelector(state => state.app);
    const { builderData, locationsData, consentsData } = useSelector(state => state.data);

    const { register, handleSubmit, watch, setValue } = useFormContext();
    const { engine, body } = useSelectedAccessory();
    const salesfrontConfig = useSalesFrontConfig();

    const { model: builderModel, grades } = builderData;
    const location = watch('location');

    React.useEffect(() => {
        if (location === '1' && !!locationsData.length) {
            setValue('location', locationsData[0].shortName);
        }
    }, []);

    const createTestDriveDescription = () =>
        `Test drive, "${builderModel.name}", "${
            grades?.find(item => item.id === gradeId)?.name || ''
        }", "${engine.name}", "${body.name}"`;

    const createOfferDescription = () =>
        `${builderData?.name} - request for offer`;

    const handleFormSubmit = ({
        firstName,
        lastName,
        email,
        //phoneCode,
        phoneNr,
        location,
        consents
    }) => {
        const payload = {
            location,
            source: window.location.href,
            description: offerModalType === 'offer' ?
                createOfferDescription() :
                createTestDriveDescription(),
            customer: {
                firstName,
                lastName,
                //phoneCode,
                phoneNumber: phoneNr,
                email,
            },
            config: salesfrontConfig,
            createActivity: offerModalType === 'offer',
            consents: consents.map(item => ({
                consent: item,
                isSigned: true
            })),
            modelFamilyName: builderModel.familyName
        };
        onSubmit(payload);
    };

    return (
        <form onSubmit={handleSubmit(handleFormSubmit)}>
            <Form.Group className="form-group">
                <Form.Label htmlFor="firstName" className="required">
                    {t('form_first_name')}
                </Form.Label>
                <Form.Control
                    id="firstName"
                    name="firstName"
                    {...register('firstName', { required: true })}
                />
            </Form.Group>
            <Form.Group className="form-group">
                <Form.Label htmlFor="lastName" className="required">
                    {t('form_last_name')}
                </Form.Label>
                <Form.Control
                    id="lastName"
                    name="lastName"
                    {...register('lastName', { required: true })}
                />
            </Form.Group>
            <Form.Group className="form-group">
                <Form.Label htmlFor="lastName" className="required">
                    {t('form_email')}
                </Form.Label>
                <Form.Control
                    id="email"
                    name="email"
                    type="email"
                    {...register('email', { required: true })}
                />
            </Form.Group>
            {/*<Form.Group className="form-group">
                <Form.Label htmlFor="phoneCode" className="required">
                    {t('form_phone_code')}
                </Form.Label>
                <Form.Control
                    id="phoneCode"
                    name="phoneCode"
                    {...register('phoneCode', { required: true })}
                />
            </Form.Group>*/}
            <Form.Group className="form-group">
                <Form.Label htmlFor="phoneNr" className="required">
                    {t('form_phone_number')}
                </Form.Label>
                <Form.Control
                    id="phoneNr"
                    name="phoneNr"
                    type="phone"
                    {...register('phoneNr', { required: true })}
                />
            </Form.Group>
            {!!locationsData.length && (
                <Form.Group className="form-group">
                    <Form.Label htmlFor="location">
                        {t('form_location')}
                    </Form.Label>
                    <Form.Select {...register('location')}>
                        {locationsData.map(item => (
                            <option key={item.shortName} value={item.shortName}>
                                {item.name}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>
            )}
            {!!consentsData.length && (
                <Form.Group className="form-group">
                    <Form.Label>{t('consent_marketing_text')}</Form.Label>
                    {consentsData.map(item => {
                        let label = item.title;
                        if (label.toLowerCase().includes('email')) label = t('form_email');
                        if (label.toLowerCase().includes('sms')) label = t('form_sms');
                        if (label.toLowerCase().includes('phone')) label = t('form_phone_number');
                        return (
                            <Form.Check
                                key={item.iri}
                                id={item.iri}
                                inline
                                type="checkbox"
                                name="consents[]"
                                value={item.iri}
                                label={label}
                                {...register('consents')}
                            />
                        )
                    })}
                </Form.Group>
            )}
            <Form.Text className="form-group">
                {t('disclaimer_legal_text')}<br/>
                <a href={t('disclaimer_legal_url')} target="_blank">{t('disclaimer_legal_more')}</a>
            </Form.Text>
            <Form.Group className="form-group form-buttons">
                <Button type="submit">{t('submit_button')}</Button>
            </Form.Group>
        </form>
    );
};

export default OfferForm;