import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { saveAs } from 'file-saver';

import { Button, Modal, Alert, Stack } from 'react-bootstrap';

import LoadingSpinner from 'components/LoadingSpinner';

import { setModalState } from 'store/entities/app';
import { usePdfDataRequest, useConfigRequest } from 'api/requests';

export const PdfModal = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    const [savedConfigId, setSavedConfigId] = React.useState(null);
    const [pdf, setPdf] = React.useState(null);
    const [error, setError] = React.useState('');

    const { pdfModal: isOpen } = useSelector(state => state.app);
    const { config } = useSelector(state => state);

    const dataPdf = usePdfDataRequest({
        params: {
            params: {
                configId: savedConfigId,
                language: i18n.language.split('-')[0],
            },
            queryKeys: [savedConfigId]
        },
        config: {
            enabled: !!savedConfigId,
        },
        success: (result) => {
            const blob = new Blob([result.data], { type: 'application/pdf' });
            setPdf(blob);
        },
        error: (error) => {
            setError(error.message);
        }
    });

    const saveConfig = useConfigRequest({
        success: (result, data) => {
            const configId = result?.data?.configId;
            setSavedConfigId(configId);
        },
        error: (error) => {
            setError(error.message);
        },
    });

    React.useEffect(() => {
        if (isOpen && !savedConfigId) saveConfig.mutate(config);
    }, [isOpen]);

    const isLoading = dataPdf.isFetching || saveConfig.isFetching;
    const showActions = !!pdf && !isLoading;

    const handleClose = () => {
        if (!isLoading) dispatch(setModalState({
            modal: 'pdfModal',
            newState: false
        }));
    };

    const handlePdfDownload = () => {
        saveAs(pdf, `${t('configuration_result')}.pdf`);
    };
    const handlePdfOpen = () => {
        const fileURL = window.URL.createObjectURL(pdf);
        const tab = window.open();
        tab.location.href = fileURL;
    };

    return (
        <Modal show={isOpen} size="lg" onHide={handleClose}>
            <Modal.Header closeButton={!isLoading}>
                <Modal.Title>{t(`pdf_modal_title`)}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="py-5">
                {isLoading && (
                    <LoadingSpinner />
                )}
                {!!error && (
                    <Alert variant="error">
                        <Alert.Heading>{t('pdf_modal_error')}</Alert.Heading>
                        {error}
                    </Alert>
                )}
                {showActions && (<>
                    <Alert variant="success">
                        {t('pdf_modal_success')}
                    </Alert>
                    <Stack className="mt-5" direction="horizontal" gap={3}>
                        <Button onClick={handlePdfDownload} variant="primary">
                            {t('btn_download')}
                        </Button>
                        <Button onClick={handlePdfOpen} variant="light">
                            {t('btn_open')}
                        </Button>
                    </Stack>
                </>)}
            </Modal.Body>
        </Modal>
    );
};
