import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  gradeId: null,
  model: null,
  code: null,
  specification: null,
  engine: null,
  body: null,
  colorCode: null,
  wheels: null,
  interior: null,
  extraPackages: [],
  accessories: [],
};

const slice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setModel: (state, { payload }) => {
      state.model = payload;
    },
    setCode: (state, { payload }) => {
      state.code = payload;
    },
    setSpecification: (state, { payload }) => {
      state.specification = payload;
    },
    setEngine: (state, { payload }) => {
      state.engine = payload;
    },
    setBody: (state, { payload }) => {
      state.body = payload;
    },
    setColor: (state, { payload }) => {
      state.colorCode = payload;
    },
    setWheels: (state, { payload }) => {
      state.wheels = payload;
    },
    setInterior: (state, { payload }) => {
      state.interior = payload;
    },
    addAccessory: (state, { payload }) => {
      state.accessories.push(payload);
    },
    removeAccessory: (state, { payload }) => {
      state.accessories = state.accessories.filter(item => item !== payload);
    },
    updateAccessories: (state, { payload }) => {
      state.accessories = payload;
    },
    addExtraPackage: (state, { payload }) => {
      state.extraPackages.push(payload);
    },
    removeExtraPackage: (state, { payload }) => {
      state.extraPackages = state.extraPackages.filter(item => item !== payload);
    },
    updateExtraPackages: (state, { payload }) => {
      state.extraPackages = payload;
    },
    setGrade: (state, { payload }) => ({ ...initialState, ...payload }),
    setConfig: (state, { payload }) => ({ ...state, ...payload }),
  },
});

export const {
  setModel,
  setEngine,
  setBody,
  setColor,
  setWheels,
  setInterior,
  setGrade,
  addAccessory,
  removeAccessory,
  updateAccessories,
  addExtraPackage,
  removeExtraPackage,
  updateExtraPackages,
  setConfig,
  setSpecification,
} = slice.actions;

export default slice.reducer;
