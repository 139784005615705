import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Accordion, Image } from 'react-bootstrap';

const TireLabels = () => {
    const { t } = useTranslation();
    
    const { tireLabelsData } = useSelector(state => state.data);

    return !tireLabelsData.length ? null : (
        <Accordion.Item eventKey="accordionTires" className="c-accordion__item">
            <Accordion.Button className="c-accordion__toggler">
                {t('tire_labels_title')}
            </Accordion.Button>
            <Accordion.Body className="c-accordion__body">
                <div className="c-tires-descr">{t('tire_labels_text')}</div>
                <ul className="c-tires">
                    {tireLabelsData.map((item) => 
                        <li className="c-tires__item" key={item.link}>
                            <figure className="c-tires__img">
                                <Image src={item.pic} />
                            </figure>
                            <a className="c-tires__label" href={item.link} target="_blank" rel="noreferrer">
                                {t('tire_labels_more')}
                            </a>
                        </li>
                    )}
                </ul>
            </Accordion.Body>
        </Accordion.Item>
    );
};

export default TireLabels;
