import axios from 'axios';
import { useMutation, useQuery } from 'react-query';

//import { RequestContext } from '../context/request.context';

import {
    BRANDS_DATA_QUERY_CACHE,
    BUILDER_DATA_QUERY_CACHE,
    CONSENTS_DATA_QUERY_CACHE,
    COMPATIBILITY_DATA_QUERY_CACHE,
    MODELS_DATA_QUERY_CACHE,
    LOCATIONS_DATA_QUERY_CACHE,
    PDF_DATA_QUERY_CACHE,
    SPECIFICATIONS_DATA_QUERY_CACHE,
    SPECIFICATION_DATA_QUERY_CACHE,
    TIRES_DATA_QUERY_CACHE,
    PDF_CONFIG_QUERY_CACHE,
    CONFIG_DATA_QUERY_CACHE
} from './cache';

const instance = axios.create({
    baseURL: '/api/v1',
});

const useQueryRequest = (queryCache = '', queryFn, props = {}) => {
    const { params = {}, config = {}, success = () => {}, error = () => {} } = props;
    //const { handleToast } = useContext(RequestContext);
    return useQuery([queryCache, ...(params.queryKeys || [])], queryFn, {
        ...config,
        onSuccess: (res) => {
            success(res);
        },
        onError: (err) => {
            error(err);
            //handleToast('error', err.message, 3000);
        },
    });
};

const useMutationRequest = (queryFn, props = {}) => {
    const { success = () => {}, error = () => {} } = props;
    //const { handleToast } = useContext(RequestContext);
    return useMutation(queryFn, {
        onSuccess: (res, data) => {
            success(res, data);
        },
        onError: (err) => {
            error(err);
            //handleToast('error', err.message, 3000);
        },
    });
};

export const useLocationsDataRequest = (props) => useQueryRequest(
    LOCATIONS_DATA_QUERY_CACHE,
    () => instance.get('/location'),
    props
);

export const useSpecificationsDataRequest = (props) => useQueryRequest(
    SPECIFICATIONS_DATA_QUERY_CACHE,
    () => instance.get('/builder/specifications', {
        params: props.params.params
    }),
    props
);

export const useSpecificationDataRequest = (props) => useQueryRequest(
    SPECIFICATION_DATA_QUERY_CACHE,
    () => instance.get(`/builder/specifications/${props.params.specification}`, {
        params: props.params.params
    }),
    props
);

export const useBuilderDataRequest = (props) => useQueryRequest(
    BUILDER_DATA_QUERY_CACHE,
    () => instance.get(`/builder/combined/${props.params.specification}`, {
        params: props.params.params
    }),
    props
);

export const useCompatibilityDataRequest = (props) => useQueryRequest(
    COMPATIBILITY_DATA_QUERY_CACHE,
    () => instance.get(`/builder/specifications/${props.params.specification}/compatibility`, {
        params: props.params.params
    }),
    props
);

export const useTiresDataRequest = (props) => useQueryRequest(
    TIRES_DATA_QUERY_CACHE,
    () => instance.get(`/labels/${props.params.specification}`),
    props
);

export const useConsentsDataRequest = (props) => useQueryRequest(
    CONSENTS_DATA_QUERY_CACHE,
    () => instance.get('/consent', {
        params: props.params.params
    }),
    props
);

export const useConfigDataRequest = (props) => useQueryRequest(
    CONFIG_DATA_QUERY_CACHE,
    () => instance.get(`/builder/config/${props.params.id}`),
    props
);

export const usePdfDataRequest = (props) => useQueryRequest(
    PDF_DATA_QUERY_CACHE,
    () => instance.get('/builder/config/pdf', {
        params: props.params.params,
        responseType: 'arraybuffer'
    }),
    props
);

export const useOfferRequest = (props) => useMutationRequest(
    (data) => instance.post('/customers/lead', data),
    props
);

export const useConfigRequest = (props) => useMutationRequest(
    (data) => instance.post('/builder/config', {
        config: { ...data },
    }),
    props
);

export const useBrandsDataRequest = (props) => useQueryRequest(
    BRANDS_DATA_QUERY_CACHE,
    () => instance.get('/brands'),
    props
);

export const useModelsDataRequest = (props) => useQueryRequest(
    MODELS_DATA_QUERY_CACHE,
    () => instance.get('/builder/models', {
        params: props.params.params
    }),
    props
);
