import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  specificationsData: [],
  rawBuilderData: {},
  builderData: {},
  bodyData: {},
  tireLabelsData: [],
  compatibilityData: {},
  locationsData: [],
  consentsData: [],
  modelsData: [],
  errorData: {}
};

const slice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    setSpecificationsData: (state, { payload }) => {
      state.specificationsData = payload.reduce((res, input) => {
        res[input.grade.id] = res[input.grade.id] || [];
        res[input.grade.id].push(input);
        return res;
      }, {});
    },
    setRawBuilderData: (state, { payload }) => {
      state.rawBuilderData = payload;
    },
    setBuilderData: (state, { payload }) => {
      const { accessories = [], techdata = [] } = payload;
      let newPayload = { ...payload };
      newPayload.accessories = accessories.reduce((obj, el) => {
        obj[el.categoryName] = [...(obj[el.categoryName] || []), el];
        return obj;
      }, {});
      newPayload.techdata = techdata.reduce((obj, el) => {
        obj[el.categoryName] = [...(obj[el.categoryName] || []), el];
        return obj;
      }, {});
      //const defaultWheels = wheels.find(item => !item.code);
      //newPayload.wheels = [defaultWheels, ...wheels.filter(item => !!item.code)];
      state.builderData = newPayload;
    },
    setBodyData: (state, { payload }) => ({...initialState,
      specificationsData: state.specificationsData,
      bodyData: [...payload].sort((a, b) => // sort asc by price
        a.price - b.price
      ).reduce((mapped, item) => { // group engines by body
        mapped[item.body.id] = mapped[item.body.id] || {...item.body, engines: []};
        if (!mapped[item.body.id].engines.some(engine => engine.eic === item.eic)) { // check duplicates by eic value
          mapped[item.body.id].engines.push({...item.engine, eic: item.eic, title: item.name, price: item.price});
        }
        return mapped;
      }, {})
    }),
    setTireLabelsData: (state, { payload }) => {
      state.tireLabelsData = payload;
    },
    setCompatibilityData: (state, { payload }) => {
      state.compatibilityData = payload;
    },
    setLocationsData: (state, { payload }) => {
      state.locationsData = payload;
    },
    setConsentsData: (state, { payload }) => {
      state.consentsData = payload.filter(item =>
        [
          'configurator consent email',
          'configurator consent phone',
          'configurator consent sms'
        ].some(el =>
          item.title.toLowerCase().includes(el)
        )
      );
    },
    setModelsData: (state, { payload }) => {
      state.modelsData = payload;
    },
    setErrorData: (state, { payload }) => {
      state.errorData = payload;
    },
    resetBuilderData: state => initialState,
  },
});

export const {
  setSpecificationsData,
  setRawBuilderData,
  setBuilderData,
  setBodyData,
  setTireLabelsData,
  setCompatibilityData,
  setLocationsData,
  setConsentsData,
  setModelsData,
  setErrorData,
  resetBuilderData
} = slice.actions;

export default slice.reducer;
