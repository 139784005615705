import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Navigate } from 'react-router';

import NavBar from 'components/NavBar';
import Footer from 'components/Footer';

import Assemble from 'views/Assemble';
import CarPartSelection from 'views/CarPartSelection';
import Conclusion from 'views/Conclusion';
import GradeSelection from 'views/GradeSelection';
import ModelSelection from 'views/ModelSelection';
import Config from 'views/Config';

import ScrollToTop from 'utils/scrollToTop';

const Router = () => (
    <BrowserRouter>
        <ScrollToTop />
        <NavBar />
        <Routes>
            <Route path="/:locale/grade" element={<GradeSelection />} />
            <Route path="/:locale/select" element={<CarPartSelection />} />
            <Route path="/:locale/assemble" element={<Assemble />} />
            <Route path="/:locale/conclusion" element={<Conclusion />} />
            <Route path="/:locale/config" element={<Config />} />
            <Route path="*" element={<ModelSelection />} />
        </Routes>
        <Footer />
    </BrowserRouter>
);

export default Router;
