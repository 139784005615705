import { useTranslation } from 'react-i18next';

import { Container, Button } from 'react-bootstrap';

import LocalizedLink from 'components/LocalizedLink';
import { ReactComponent as RewindIco } from 'assets/images/icons/Rewind.svg';

const NotFound = ({ error, onRefetch }) => {
    const { t } = useTranslation();
    return (
        <section className="c-section c-error">
            <Container>
                <h5 className="c-error__title">{t('error_title', 'Something goes wrong...')}</h5>
                {/*<p className="c-error__descr">{t('error_descr', 'Please try later')}</p>*/}
                <p className="c-error__descr">{error?.message}</p>
                <p className="c-error__btnline">
                    {error?.redirect && (
                        <Button variant="primary" as="a" href={error.redirect}>
                            {t('error_button_back', 'Go to homepage')}
                        </Button>
                    )}
                    {error?.refetch && (
                        <Button variant="primary" onClick={onRefetch}>
                            <RewindIco className="btn-ico btn-ico-left" />
                            {t('error_button_refetch', 'Refresh data')}
                        </Button>
                    )}
                    {error?.route && (
                        <LocalizedLink to={error.route} className="btn btn-primary">
                            {t('error_button_route', 'Go to previous step')}
                        </LocalizedLink>
                    )}
                </p>
            </Container>
        </section>
    );
};

export default NotFound;