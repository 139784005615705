export const BRANDS_DATA_QUERY_CACHE = 'brands-data';
export const BUILDER_DATA_QUERY_CACHE = 'builder-data';
export const CONSENTS_DATA_QUERY_CACHE = 'consents-data';
export const COMPATIBILITY_DATA_QUERY_CACHE = 'compatibility-data';
export const MODELS_DATA_QUERY_CACHE = 'models-data';
export const LOCATIONS_DATA_QUERY_CACHE = 'locations-data';
export const PDF_DATA_QUERY_CACHE = 'pdf-data';
export const SPECIFICATIONS_DATA_QUERY_CACHE = 'specifications-data';
export const SPECIFICATION_DATA_QUERY_CACHE = 'specification-data';
export const TIRES_DATA_QUERY_CACHE = 'tires-data';

export const PDF_CONFIG_QUERY_CACHE = 'pdf-config';
export const CONFIG_DATA_QUERY_CACHE = 'config-data';