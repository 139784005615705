import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { Accordion, Container, Row, Col, Image, Card, ListGroup } from 'react-bootstrap';

import StickyAside from 'components/StickyAside';
import RewindButton from 'components/RewindButton';
import Price from 'components/Price';
import { OfferModal, PdfModal, SaveCarConfigModal } from 'components/modals';
import NotFound from 'components/NotFound';

import SelectedAccessories from './SelectedAccessories';
import SelectedPackages from './SelectedPackages';
import TireLabels from './TireLabels';
import TechData from './TechData';

import useTotalPrice from 'hooks/useTotalPrice';
import {
    setConsentsData,
    setTireLabelsData,
    setLocationsData,
    setErrorData
} from 'store/entities/data';

import PlaceholderCar from 'assets/images/placeholder-car.png';

import {
    useConsentsDataRequest,
    useTiresDataRequest,
    useLocationsDataRequest
} from 'api/requests';

const Conclusion = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const totalPrice = useTotalPrice();

    const { builderData, errorData } = useSelector(state => state.data);
    const { specification, colorCode, interior } = useSelector(state => state.config);

    const selectedExterior = builderData.exteriors?.find(item => item.code === colorCode);
    const selectedInterior = builderData.interiors?.find(item => item.code === interior);

    useConsentsDataRequest({
        params: {
            params: {
                limit: 100,
                isActive: true,
            }
        },
        success: (result) => {
            dispatch(setConsentsData(result.data));
        },
        error: (error) => {
            console.log('Consents error: ', error.message);
        }
    });

    useTiresDataRequest({
        params: {
            specification
        },
        success: (result) => {
            dispatch(setTireLabelsData(result.data));
        },
        error: (error) => {
            console.log('Tires labels error: ', error.message);
        }
    });

    useLocationsDataRequest({
        success: (result) => {
            dispatch(setLocationsData(result.data));
        },
        error: (error) => {
            console.log('Locations error: ', error.message);
        }
    });

    React.useEffect(() => {
        dispatch(setErrorData(!specification ? {
            type: 'model',
            message: 'Model ID or model CODE is missing',
            redirect: '/'
        } : {}));
    }, [specification]);

    return (
        !errorData?.type ? (
            builderData.eic ? (
                <section className="c-section">
                    <Container>
                        <Row className="gx-lg-5">
                            <Col lg="8">
                                <div className="c-vehicle">
                                    <div className="c-vehicle__body">
                                        <div className="c-vehicle__caption c-caption">
                                            <h2 className="c-caption__title">{builderData.model?.name}</h2>
                                            <p className="c-caption__descr">{builderData.name}</p>
                                            <p className="c-caption__btn">
                                                <RewindButton href="/assemble" text={t('changes_selections')} />
                                            </p>
                                        </div>
                                    </div>
                                    <figure className="c-vehicle__img c-vehicle__img--right">
                                        <Image src={selectedExterior ? selectedExterior.pictures.main : PlaceholderCar} />
                                    </figure>
                                </div>
                                <h5 className="c-section__subtitle">{t('your_selected_accessories')}</h5>
                                <ListGroup variant="flush">
                                    {selectedExterior && (
                                        <ListGroup.Item>
                                            <span className="list-group-label">{selectedExterior.name}</span>
                                            <b className="list-group-value"><Price price={selectedExterior.price} placeholder="&ndash;" /></b>
                                        </ListGroup.Item>
                                    )}
                                    {selectedInterior && (
                                        <ListGroup.Item>
                                            <span className="list-group-label">{selectedInterior.name}</span>
                                            <b className="list-group-value"><Price price={selectedInterior.price} placeholder="&ndash;" /></b>
                                        </ListGroup.Item>
                                    )}
                                </ListGroup>
                                <Accordion flush className="c-accordion">
                                    <SelectedAccessories />
                                    <SelectedPackages />
                                </Accordion>
                                <Card className="c-total">
                                    <Card.Body className="c-total__body">
                                        <Card.Title className="c-total__title">{t('total')}</Card.Title>
                                        <Card.Text className="c-total__price">
                                            <Price price={totalPrice} />
                                        </Card.Text>
                                        <Card.Text className="c-total__hint">
                                            <b>{t('consent_short_title')}</b><br/>
                                            <span>{t('consent_short_text')}</span>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                                <Accordion flush className="c-accordion">
                                    <TechData />
                                    <TireLabels />
                                </Accordion>
                            </Col>
                            <Col lg="4" className="c-aside-container">
                                <StickyAside />
                            </Col>
                        </Row>
                    </Container>
                    <SaveCarConfigModal />
                    <OfferModal />
                    <PdfModal />
                </section>
            ) : (
                <NotFound error={{
                    type: 'builder-data',
                    route: '/select',
                    message: 'Please, select vehicle engine'
                }} />
            )
        ) : (
            <NotFound error={errorData} />
        )
    );
};

export default Conclusion;
