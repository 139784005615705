import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import queryString from 'query-string';

import { Container, Row } from 'react-bootstrap';

import ConfigLoadingPlaceholder from 'views/Config/ConfigLoadingPlaceholder';
import ConfigFailedLoading from 'views/Config/ConfigFailedLoading';
import LoadingSpinner from 'components/LoadingSpinner';

import useLocale from 'hooks/useLocale';
import { setConfig } from 'store/entities/config';
import { setBuilderData } from 'store/entities/data';
import { useBuilderDataRequest, useConfigDataRequest } from 'api/requests';

const Config = () => {
    const { i18n } = useTranslation();
    const locale = useLocale();
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isConfigLoading, setIsConfigLoading] = React.useState(true);
    const [fetchedConfig, setFetchedConfig] = React.useState(null);

    const ENV = window.ENV[window.location.hostname] || window.ENV.default;

    const { id } = queryString.parse(location.search);

    const dataBuilder = useBuilderDataRequest({
        params: {
            specification: fetchedConfig.specification,
            params: {
                brand: ENV.BRAND_NAME,
                language: locale,
                'options.code': [
                    fetchedConfig.colorCode,
                    fetchedConfig.wheels,
                ],
            }
        },
        config: {
            enabled: false,
        },
        success: (result) => {
            saveBuilderData(result.data);
        },
        error: (error) => {
            setIsConfigLoading(false)
        }
    });

    const dataConfig = useConfigDataRequest({
        params: {
            id
        },
        config: {
            enabled: false,
        },
        success: (result) => {
            saveSpecification(result);
        },
        error: (error) => {
            setIsConfigLoading(false)
        }
    });

    React.useEffect(() => {
        if (fetchedConfig) dataBuilder.refetch();
    }, [fetchedConfig]);

    React.useEffect(() => {
        if (id) dataConfig.refetch();
    }, [id]);

    const saveBuilderData = (data) => {
        dispatch(setBuilderData(data));
        navigate(`/${i18n.language}/conclusion`);
    };

    const saveSpecification = (result) => {
        if (!result.data) return;
        const { config } = result.data;
        dispatch(setConfig(config));
        setFetchedConfig(config);
    };

    return (<>
        {isConfigLoading && (
            <LoadingSpinner />
        )}
        <Container style={{ minHeight: '70vh' }}>
            <Row className="mt-5">
                {isConfigLoading ? (
                    <ConfigLoadingPlaceholder />
                ) : (
                    <ConfigFailedLoading />
                )}
            </Row>
        </Container>
    </>);
};

export default Config;
